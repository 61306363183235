import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModule} from './login/login.module';
import {SharedModule} from '../shared/shared.module';
import {HttpClient, HttpHandler, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {AuthenticatedUserGuard} from './guards/authenticated-user.guard';
import {BasePageComponent} from './components/base-page/base-page.component';
import {HelpModule} from './modules/help/help.module';
import {AccountDropdownComponent} from './components/account-dropdown/account-dropdown.component';
import {NomAttributionComponent} from './components/nom-attribution/nom-attribution.component';
import {TermsOfUseComponent} from './components/terms-of-use/terms-of-use.component';
import {StopTimeComponent} from './components/stop-time/stop-time.component';
import {ApiKeyModule} from './modules/api-key/api-key.module';
import {HttpInterceptorService} from './services/http-interceptor.service';
import {Angulartics2GoogleAnalytics, Angulartics2Module} from 'angulartics2';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MapModule} from './modules/map/map.module';
import {LayerModule} from './modules/layer/layer.module';
import {OutageModule} from './modules/outage/outage.module';
import {LiteModule} from './modules/lite/lite.module';
import {ResolutionCheckComponent} from './components/resolution-check/resolution-check.component';
import {NotificationTypePipe} from './pipes/notification-type.pipe';
import {SystemNewsModule} from './modules/system-news/system-news.module';
import {RecentEventsModule} from './modules/recent-events/recent-events.module';
import {UserModule} from './modules/user/user.module';
import {ReportModule} from './modules/report/report.module';
import {LockTimeBannerComponent} from './components/lock-time-banner/lock-time-banner.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {Survey123PopoverComponent} from './modules/layer/popovers/survery123-popover/survey123-popover.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/button-toggle';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

export function EagleIHttpServiceFactory(handler: HttpHandler) {
    return new HttpInterceptorService(handler);
}

@NgModule({
    declarations: [
        AppComponent,
        BasePageComponent,
        AccountDropdownComponent,
        NomAttributionComponent,
        TermsOfUseComponent,
        StopTimeComponent,
        ResolutionCheckComponent,
        NotificationTypePipe,
        LockTimeBannerComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        LoginModule,
        MapModule,
        LayerModule,
        SharedModule,
        ApiKeyModule,
        OutageModule,
        HelpModule,
        MatIconModule,
        LiteModule,
        SystemNewsModule,
        RecentEventsModule,
        UserModule,
        Angulartics2Module.forRoot(),
        ReportModule,
        Survey123PopoverComponent,
        MatSliderModule,
        FontAwesomeModule,
    ],
    providers: [
        AuthenticatedUserGuard,
        MatIconRegistry,
        {
            provide: HttpClient,
            useFactory: EagleIHttpServiceFactory,
            deps: [HttpHandler],
        },
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: {hideSingleSelectionIndicator: true, hideMultipleSelectionIndicator: true}},
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
