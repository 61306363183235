import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

declare let gtag: (type: string, key: string, options: any) => void;

@Component({
    selector: 'app-root',
    template: ` <router-outlet></router-outlet>`,
    styles: [],
})
export class AppComponent {
    constructor(private router: Router, iconLibrary: FaIconLibrary) {
        iconLibrary.addIconPacks(fas, far);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd && (window as any).ga_ei_key))
            .subscribe((event: NavigationEnd) => {
                gtag('config', (window as any).ga_ei_key, {
                    page_path: event.urlAfterRedirects,
                });
            });
    }
}
